import { VpxAction } from './actions';
import * as ActionType from './constants';

const INIT_STATE = {
    list: null,
    listLoading: false,
    listError: null,
    userProfile: null,
    companyProfile: null,
    profileLoading: false,
    profileError: false,
    actionError: null,
    actionSuccessMessage: null,
    externalList: null,
    externalListLoading: false,
    externalListError: null
};

type State = {
    list?: {};
    listLoading: boolean;
    listError?: string;
    userProfile?: {};
    companyProfile?: {};
    profileLoading: boolean;
    profileError: boolean;
    actionError?: string;
    actionSuccessMessage?: string;
    externalList: {};
    externalListLoading: boolean;
    externalListError?: string;
};

const mapChangeValuePropositionActions = (vpElement, targetVp, newAction) => {
    if (vpElement?.Details?.VpID === targetVp && !!vpElement?.Actions) {
        vpElement.Actions = { ...vpElement.Actions, ...newAction } ;
    }
    return vpElement;
}

const Vpx = (state: State = INIT_STATE, action: VpxAction) => {
    switch (action.type) {
        case ActionType.SEARCH_VPX_VP_EXCHANGE_LIST:
            return { ...state, listLoading: true, listError: null };
        case ActionType.SEARCH_VPX_VP_EXCHANGE_LIST_SUCCESS:
            return { ...state, list: action.payload.vpxList, listLoading: false };
        case ActionType.SEARCH_VPX_VP_EXCHANGE_LIST_FAILED:
            return { ...state, list: [], listError: action.payload, listLoading: false };

        case ActionType.VALUE_PROPOSITION_VIEW_USER_PROFILE:
            return { ...state, profileLoading: true, profileError: null, userProfile: null };
        case ActionType.VALUE_PROPOSITION_VIEW_USER_PROFILE_SUCCESS:
            return { ...state, profileLoading: false, userProfile: action.payload.userPofile };
        case ActionType.VALUE_PROPOSITION_VIEW_USER_PROFILE_FAILED:
            return { ...state, profileLoading: false, profileError: action.payload };

        case ActionType.VALUE_PROPOSITION_VIEW_COMPANY_PROFILE:
            return { ...state, profileLoading: true, profileError: null, userProfile: null };
        case ActionType.VALUE_PROPOSITION_VIEW_COMPANY_PROFILE_SUCCESS:
            return { ...state, profileLoading: false, companyProfile: action.payload.companyProfile };
        case ActionType.VALUE_PROPOSITION_VIEW_COMPANY_PROFILE_FAILED:
            return { ...state, profileLoading: false, profileError: action.payload };

        case ActionType.LOAD_EXTERNAL_LIST:
            return { ...state, externalList: null, externalListLoading: true, externalListError: null };
        case ActionType.LOAD_EXTERNAL_LIST_SUCCESS:
            return { ...state, externalList: action.payload.extenalList, externalListLoading: false };
        case ActionType.LOAD_EXTERNAL_LIST_FAILED:
            return { ...state, externalListLoading: false, externalListError: action.payload };

        case ActionType.CLEAR_VALUE_PROPOSITION_PROFILE_ERROR:
            return { ...state, profileError: null };
        case ActionType.ACCEPT_TO_FOLLOW:
            return { ...state, accepting: true, error: null };
        case ActionType.ACCEPT_TO_FOLLOW_SUCCESS:
            return { ...state, accepting: false, error: null, actionSuccessMessage: 'Accepted to follow the value proposition successfully.' };
        case ActionType.ACCEPT_TO_FOLLOW_FAILED:
            return { ...state, accepting: false, error: action.payload };
        case ActionType.REQUEST_TO_FOLLOW:
            return { ...state, listLoading: true, actionError: null, actionSuccessMessage: null };
        case ActionType.REQUEST_TO_FOLLOW_SUCCESS: {
            let newState = { ...state, listLoading: false, actionSuccessMessage: 'Request to follow the value proposition was sent successfully.' };
            if (!state.list) {
                return newState;
            }
            const newVpList = state.list.map(vpElement => mapChangeValuePropositionActions(
                vpElement, action.payload.vpId, { CanFollow: false, WaitingForApproval: true }
            ));
            newState = { ...newState, list: newVpList }

            const newUserProfileVpList = state.userProfile?.ValuePropositions?.map(vpElement =>
                mapChangeValuePropositionActions(
                    vpElement, action.payload.vpId, { CanFollow: false, WaitingForApproval: true }
                )
            );
            if (!!newUserProfileVpList) {
                newState = {
                    ...newState,
                    userProfile: { ...state.userProfile, ValuePropositions: newUserProfileVpList }
                };
            }

            const newCompanyProfileVpList = state.companyProfile?.ValuePropositions?.map(vpElement =>
                mapChangeValuePropositionActions(
                    vpElement, action.payload.vpId, { CanFollow: false, WaitingForApproval: true }
                )
            );
            if (!!newCompanyProfileVpList) {
                newState = {
                    ...newState,
                    companyProfile: { ...state.companyProfile, ValuePropositions: newCompanyProfileVpList }
                };
            }
            return newState;
        }
        case ActionType.REQUEST_TO_FOLLOW_FAILED:
            return { ...state, list: [], actionError: action.payload, listLoading: false };

        case ActionType.REQUEST_TO_UNFOLLOW:
            return { ...state, listLoading: true, actionError: null };
        case ActionType.REQUEST_TO_UNFOLLOW_SUCCESS: {
            const newVpList = state.list.map(vpElement => mapChangeValuePropositionActions(
                vpElement, action.payload.vpId, { CanFollow: true, CanUnfollow: false, WaitingForApproval: false }
            ));
            let newState = { ...state, list: newVpList, listLoading: false };

            const newUserProfileVpList = state.userProfile?.ValuePropositions?.map(vpElement =>
                mapChangeValuePropositionActions(
                    vpElement, action.payload.vpId, { CanFollow: true, CanUnfollow: false, WaitingForApproval: false }
                )
            );
            if (!!newUserProfileVpList) {
                newState = {
                    ...newState,
                    userProfile: { ...state.userProfile, ValuePropositions: newUserProfileVpList }
                };
            }

            const newCompanyProfileVpList = state.companyProfile?.ValuePropositions?.map(vpElement =>
                mapChangeValuePropositionActions(
                    vpElement, action.payload.vpId, { CanFollow: true, CanUnfollow: false, WaitingForApproval: false }
                )
            );
            if (!!newCompanyProfileVpList) {
                newState = {
                    ...newState,
                    companyProfile: { ...state.companyProfile, ValuePropositions: newCompanyProfileVpList }
                };
            }
            return newState;
        }
        case ActionType.REQUEST_TO_UNFOLLOW_FAILED:
            return { ...state, list: [], actionError: action.payload, listLoading: false };
        default:
            return { ...state };
    }
}

export default Vpx;
