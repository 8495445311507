/* SEARCH VPX */
export const SEARCH_VPX_VP_EXCHANGE_LIST = 'SEARCH_VPX_VP_EXCHANGE_LIST';
export const SEARCH_VPX_VP_EXCHANGE_LIST_SUCCESS = 'SEARCH_VPX_VP_EXCHANGE_LIST_SUCCESS';
export const SEARCH_VPX_VP_EXCHANGE_LIST_FAILED = 'SEARCH_VPX_VP_EXCHANGE_LIST_FAILED';

export const VALUE_PROPOSITION_VIEW_USER_PROFILE = 'VALUE_PROPOSITION_VIEW_USER_PROFILE';
export const VALUE_PROPOSITION_VIEW_USER_PROFILE_SUCCESS = 'VALUE_PROPOSITION_VIEW_USER_PROFILE_SUCCESS';
export const VALUE_PROPOSITION_VIEW_USER_PROFILE_FAILED = 'VALUE_PROPOSITION_VIEW_USER_PROFILE_FAILED';

export const VALUE_PROPOSITION_VIEW_COMPANY_PROFILE = 'VALUE_PROPOSITION_VIEW_COMPANY_PROFILE';
export const VALUE_PROPOSITION_VIEW_COMPANY_PROFILE_SUCCESS = 'VALUE_PROPOSITION_VIEW_COMPANY_PROFILE_SUCCESS';
export const VALUE_PROPOSITION_VIEW_COMPANY_PROFILE_FAILED = 'VALUE_PROPOSITION_VIEW_COMPANY_PROFILE_FAILED';

export const CLEAR_VALUE_PROPOSITION_PROFILE_ERROR = 'CLEAR_VALUE_PROPOSITION_PROFILE_ERROR';

export const ACCEPT_TO_FOLLOW = 'ACCEPT_TO_FOLLOW';
export const ACCEPT_TO_FOLLOW_SUCCESS = 'ACCEPT_TO_FOLLOW_SUCCESS';
export const ACCEPT_TO_FOLLOW_FAILED = 'ACCEPT_TO_FOLLOW_FAILED';

export const REQUEST_TO_FOLLOW = 'REQUEST_TO_FOLLOW';
export const REQUEST_TO_FOLLOW_SUCCESS = 'REQUEST_TO_FOLLOW_SUCCESS';
export const REQUEST_TO_FOLLOW_FAILED = 'REQUEST_TO_FOLLOW_FAILED';

export const REQUEST_TO_UNFOLLOW = 'REQUEST_TO_UNFOLLOW';
export const REQUEST_TO_UNFOLLOW_SUCCESS = 'REQUEST_TO_UNFOLLOW_SUCCESS';
export const REQUEST_TO_UNFOLLOW_FAILED = 'REQUEST_TO_UNFOLLOW_FAILED';

export const LOAD_EXTERNAL_LIST = 'LOAD_EXTERNAL_LIST';
export const LOAD_EXTERNAL_LIST_SUCCESS = 'LOAD_EXTERNAL_LIST_SUCCESS';
export const LOAD_EXTERNAL_LIST_FAILED = 'LOAD_EXTERNAL_LIST_FAILED';
