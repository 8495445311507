import { all, call, fork, put, takeLatest } from '@redux-saga/core/effects';

import * as Actions from './actions';
import * as ActionsType from './constants';
import { fetchPostMethod, GetErrorMessageFromStatus } from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
import { apiServiceUrl } from '../../constants';

const vpApiServiceUrl = `${apiServiceUrl}/ValueProposition`;
const followersApiServiceUrl = `${apiServiceUrl}/Followers`;

function* searchVpxVpExchangeList({ payload: { companyName, vpName, ownerName, groupId, codeId, hashtag }}) {
    try {
        const user = getLoggedInUser();
        const body = {
            CompanyName: companyName,
            Name: vpName,
            OwnerName: ownerName,
            GroupId: groupId,
            CodeId: codeId,
            Hashtag: hashtag
        };
        const response = yield call(
            fetchPostMethod,
            `${vpApiServiceUrl}/SearchVP`,
            user.token,
            body
        );

        if (response.status === 200) {
            const { vpList, status } = yield response.data.then((response) => {
                return (response.status === 200)
                    ? { vpList: response.results }
                    : { status: response.status };
            }).catch(() => ({ status: 500 }));
            if (vpList) {
                yield put(Actions.searchVpxVpExchangeListSuccess(vpList));
            } else {
                yield put(Actions.searchVpxVpExchangeListFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.searchVpxVpExchangeListFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.searchVpxVpExchangeListFailed(GetErrorMessageFromStatus(error)));
    }
}

function* vpViewUserProfile({ payload: { userId }}) {
    try {
        const user = getLoggedInUser();
        const body = { Id: userId };
        const response = yield call(
            fetchPostMethod,
            `${vpApiServiceUrl}/ViewUserProfile`,
            user.token,
            body
        );

        if (response.status === 200) {
            const { profile, status } = yield response.data.then((response) => (
                { profile: { Owner: response.owner, ValuePropositions: response.result }}
            )).catch(() => ({ status: 500 }));
            if (profile) {
                yield put(Actions.vpViewUserProfileSuccess(profile));
            } else {
                yield put(Actions.vpViewUserProfileFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.vpViewUserProfileFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.vpViewUserProfileFailed(GetErrorMessageFromStatus(error)));
    }
}

function* vpViewCompanyProfile({ payload: { companyId }}) {
    try {
        const user = getLoggedInUser();
        const body = { Id: companyId };
        const response = yield call(
            fetchPostMethod,
            `${vpApiServiceUrl}/ViewCompanyProfile`,
            user.token,
            body
        );

        if (response.status === 200) {
            const { profile, status } = yield response.data
                .then((response) => ({ profile: { Company: response.company, ValuePropositions: response.result } }))
                .catch(() => ({ status: 500 }));
            if (profile) {
                yield put(Actions.vpViewCompanyProfileSuccess(profile));
            } else {
                yield put(Actions.vpViewCompanyProfileFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.vpViewCompanyProfileFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.vpViewCompanyProfileFailed(GetErrorMessageFromStatus(error)));
    }
}

function* loadExternalList({ payload: { permissionType }}) {
    try {
        const user = getLoggedInUser();
        const body = {
            PermissionType : permissionType,
        };
        const response = yield call(
            fetchPostMethod,
            `${followersApiServiceUrl}/ExternalList`,
            user.token,
            body
        );

        if (response.status === 200) {
            const { status, externalList } = yield response.data
                .then((response) => ({status: response.status, externalList: response.items }))
                .catch(() => ({ status: 500 }));
            if (status === 200 && !!externalList) {
                yield put(Actions.loadExternalListSuccess(externalList));
            } else {
                yield put(Actions.loadExternalListFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.loadExternalListFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.loadExternalListFailed(GetErrorMessageFromStatus(error)));
    }
}

function* acceptToFollow({ payload: { vpId }}) {
    try {
        const user = getLoggedInUser();
        const body = {
            ValuePropositionId : vpId,
        };
        const response = yield call(
            fetchPostMethod,
            `${followersApiServiceUrl}/AcceptToFollow`,
            user.token,
            body
        );

        if (response.status === 200) {
            const { status } = yield response.data
                .then((response) => ({ status: response.status }))
                .catch(() => ({ status: 500 }));
            if (status === 200) {
                yield put(Actions.acceptToFollowSuccess(vpId));
            } else {
                yield put(Actions.acceptToFollowFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.acceptToFollowFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.acceptToFollowFailed(GetErrorMessageFromStatus(error)));
    }
}

function* requestToFollow({ payload: { vpId }}) {
    try {
        const user = getLoggedInUser();
        const body = {
            ValuePropositionId : vpId,
        };
        const response = yield call(
            fetchPostMethod,
            `${followersApiServiceUrl}/RequestToFollow`,
            user.token,
            body
        );

        if (response.status === 200) {
            const { status } = yield response.data
                .then((response) => ({ status: response.status }))
                .catch(() => ({ status: 500 }));
            if (status === 200) {
                yield put(Actions.requestToFollowSuccess(vpId));
            } else {
                yield put(Actions.requestToFollowFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.requestToFollowFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.requestToFollowFailed(GetErrorMessageFromStatus(error)));
    }
}

function* requestToUnfollow({ payload: { vpId }}) {
    try {
        const user = getLoggedInUser();
        const body = {
            ValuePropositionId : vpId,
        };
        const response = yield call(
            fetchPostMethod,
            `${followersApiServiceUrl}/Unfollow`,
            user.token,
            body
        );

        if (response.status === 200) {
            const { status } = yield response.data
                .then((response) => ({status: response.status}))
                .catch(() => ({ status: 500 }));
            if (status === 200) {
                yield put(Actions.requestToUnfollowSuccess(vpId));
            } else {
                yield put(Actions.requestToUnfollowFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.requestToUnfollowFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.requestToUnfollowFailed(GetErrorMessageFromStatus(error)));
    }
}

export function* watchSearchVpxVpExchangeList(): any {
    yield takeLatest(ActionsType.SEARCH_VPX_VP_EXCHANGE_LIST, searchVpxVpExchangeList);
}

export function* watchVpViewUserProfile(): any {
    yield takeLatest(ActionsType.VALUE_PROPOSITION_VIEW_USER_PROFILE, vpViewUserProfile);
}

export function* watchVpViewCompanyProfile(): any {
    yield takeLatest(ActionsType.VALUE_PROPOSITION_VIEW_COMPANY_PROFILE, vpViewCompanyProfile);
}

export function* watchAcceptToFollow(): any {
    yield takeLatest(ActionsType.ACCEPT_TO_FOLLOW, acceptToFollow);
}

export function* watchRequestToFollow(): any {
    yield takeLatest(ActionsType.REQUEST_TO_FOLLOW, requestToFollow);
}

export function* watchRequestToUnfollow(): any {
    yield takeLatest(ActionsType.REQUEST_TO_UNFOLLOW_FAILED, requestToUnfollow);
}

export function* watchLoadExternalList(): any {
    yield takeLatest(ActionsType.LOAD_EXTERNAL_LIST, loadExternalList);
}

function* vpx(): any {
    yield all([
        fork(watchSearchVpxVpExchangeList),
        fork(watchVpViewUserProfile),
        fork(watchVpViewCompanyProfile),
        fork(watchAcceptToFollow),
        fork(watchRequestToFollow),
        fork(watchRequestToUnfollow),
        fork(watchLoadExternalList),
    ]);
}

export default vpx;
